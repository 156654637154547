import React, { useContext } from 'react'
import '../css/Navbar.css'
import PropTypes from 'prop-types';
import {CircleUserRound, Search} from 'lucide-react';
import { OverlayContext } from '../Context/ContextProvider';
import { LoginLayout, SearchLayout, SignupLayout } from '../Context/Constant';

const Navbar = (props) => {

    const overlayVisibleFunc = useContext(OverlayContext);
    const OverlayFunc = (layout) => {
        overlayVisibleFunc(layout);
    }

    return (
        <>
            <div className='navbarContainer'>
                <nav id='navbar'>
                    <label>{props.brandName}</label>
                    <div className='button_container show'>
                        <button className='buttons' onClick={() => OverlayFunc(LoginLayout)}>Log in</button>
                        <button className='buttons active' onClick={() => OverlayFunc(SignupLayout)}>Sign up</button>
                    </div>
                    <div className='userLoggedIn hide'>
                        <Search className='react-icon' size={20} onClick={() => OverlayFunc(SearchLayout)} />
                        <CircleUserRound className='react-icon' size={20}/>
                    </div>
                </nav>
            </div>
        </>
    )
}

Navbar.propType = {
    brandName: PropTypes.string.isRequired,
}

export default Navbar;