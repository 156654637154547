import React, {useState, useContext} from 'react';
import { X } from 'lucide-react';
import '../css/Filter.css';
import PropTypes from 'prop-types';
import { FilterContext } from '../Context/ContextProvider';
import { RemoveAction } from '../Context/Constant';


const Filter = (props) => {
    const [visibility, setVisibility] = useState(true);
    const setChangesFunc = useContext(FilterContext);
    
    const hideDiv = () => {
        setVisibility(!visibility);
        setChangesFunc(props.data, RemoveAction);
    }

    return (
        <>
            <div className={`Filter-Cont ${visibility ? '': 'hide'}`}>
                <span>{props.data.name}</span>
                <X size={13} onClick={hideDiv} className='close'/>
            </div>
        </>
    )
}

Filter.propType = {
    data: PropTypes.string.isRequired
}

export default Filter;