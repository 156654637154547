import React, { useEffect, useState, lazy, Suspense } from 'react'
import '../css/MainContainer.css';
import { SlidersHorizontal } from 'lucide-react';
import FilterWidget from '../components/FilterWidget';
import Filter from '../components/Filter';
import { FilterContext, PagerContext } from '../Context/ContextProvider';
import { AddAction, HostData, RemoveAction } from '../Context/Constant';
import axios from 'axios';
const CardContainer = lazy(() => import('../components/CardContainer'));


const MainContainer = (props) => {
    
    const [datas, setData] = useState([]);
    //useState to store FilterData
    const [filterData, setFilterData] = useState([]);

    useEffect(() => {
        axios.post(HostData, filterData).then(response => {
            // Handle successful response
            setData(response.data.data);
          })
          .catch(error => {
            // Handle error
            console.error('Error:', error);
          });
        
    }, [filterData]);

    const setFilters = (data, action) => {
        if(action === AddAction){
            setFilterData([...filterData, data]);
        }else if(action === RemoveAction){
            setFilterData(filterData.filter((item) => item.raw !== data.raw))
        }
    }

    const clearFilters = () => {
        setFilterData([]);
    }

    return (
        <>
            <FilterContext.Provider value={setFilters}>
                <PagerContext.Provider value=''>
                    <div id='bodyContainer'>
                        <div className='cont-1'>
                            <div>
                                <span className='categoriesText'>Categories</span>
                            </div>
                            <div className='filter-cat'>
                                <FilterWidget title="Device type" elements={["Windows", "Linux", "Cisco", "Meraki"]}/>
                                <FilterWidget title="Collector" elements={["Agent", "Gateway", "Azure", "AWS","GCP","Meraki", "Synthetics"]}/>
                                <FilterWidget title="Generation" elements={["G1", "G2", "G3"]}/>
                            </div>
                        </div>
                        <div className='cont-2'>
                            <span className='categoriesText'>Monitors</span>
                            {
                                filterData.length > 0 && <div className='boxHintCont'>
                                    <div className="elem hideCont">
                                        <SlidersHorizontal size={14}/>
                                        <span className='filterText'>Filters</span>
                                        {
                                            filterData.map((data) => {
                                                return <Filter data={data}/>;
                                            })
                                        }
                                        <span className='clearAll' onClick={clearFilters}>Clear All</span>
                                    </div>
                                </div>
                            }
                            
                            <Suspense fallback={<div>Loading...</div>}>
                                <CardContainer data={datas} page={0} max={10}/>
                            </Suspense>

                        </div>
                    </div>
                </PagerContext.Provider>
            </FilterContext.Provider>
        </>
    )
}


export default MainContainer;