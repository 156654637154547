import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import '../css/FilterWidget.css';
import { Plus, Minus } from 'lucide-react';
import { FilterContext } from '../Context/ContextProvider';
import { AddAction, RemoveAction } from '../Context/Constant';

const FilterWidget = (props) => {

    const [isChecked, setCheck] = useState(false);
    const FilterSetFunc = useContext(FilterContext);

    const toggleCont = () => {
        setCheck(!isChecked);
    }

    const FilterData = (event) => {
        const dataset = event.target.dataset.name;
        const preparedData = {
            name: dataset.split('-')[1],
            title: dataset.split('-')[0].replace("_", " "),
            raw:dataset
        }
        if(event.target.checked){
            FilterSetFunc(preparedData, AddAction);
        }else{
            FilterSetFunc(preparedData, RemoveAction);
        }

        
    }

    return (
        <>
            <div className='filterwidget'>
                <div className={`accordian ${isChecked ? 'active' : ''}`} onClick={toggleCont}>
                    <span>{props.title}</span>
                    {
                        isChecked ? <Minus size={16}/> : <Plus size={16}/>
                    }
                </div>
                <div className={`filterOptions ${isChecked ? '' : 'hide'}`}>
                    {
                        props.elements.map((data) => 
                            <div className='options' key={`${props.title.replace(/\s/g, "_")}-${data}`}>
                                <input type='checkbox' data-name={`${props.title.replace(/\s/g, "_")}-${data}`} className='checkbox1' onChange={FilterData}/>
                                <label>{data}</label>
                            </div>)
                    }
                </div>
            </div>
        </>
    )
}

FilterWidget.propType = {
    title: PropTypes.string.isRequired,
    elements: PropTypes.object.isRequired
}

export default FilterWidget;