import react from "react";
import "../css/Footer.css";

const Footer = (props) => {
    return (
        <>
            <div id="Footer">
                <div className="copyright item">
                    <p className="brandName">Monit <span className="brandNameColor">Marketplace</span></p>
                    <p className="subTypo">One place to find all the monitors for all devices.</p>
                </div>
                <div className="navigator item">
                    <div className="sectionCont">
                        <p className="headTypo">Product</p>
                        <p className="subTypo">Developer</p>
                        <p className="subTypo">Contact Us</p>
                        <p className="subTypo">FAQ</p>
                        <p className="subTypo">Raise Request</p>
                    </div>
                    <div className="sectionCont">
                        <p className="headTypo">Legal</p>
                        <p className="subTypo">Terms</p>
                        <p className="subTypo">Privacy</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;