import React from "react";
import "../css/Overlay.css";
import { X } from "lucide-react";
import { LoginLayout, SearchLayout, SignupLayout } from "../Context/Constant";
import LoginForm from "../components/AuthComp/LoginForm";

const Overlay = (props) => {

    const closePopUp = () => {
        var popUp = document.querySelector("#overlay");
        popUp.style.display = "none";
    }

    const searchButtonPressed = () => {
        const inputElem = document.querySelector("#searchInput");
        const val = inputElem.value;

        if(val){
            closePopUp();
        }
    }


    return (
        <>
            <div id="overlay">
                <X size={24} className="closePopUp" onClick={closePopUp}/>
                <div className={props.layout === SearchLayout ? "searchCont" : "hide"}>

                    <input 
                        id="searchInput" 
                        type="text"
                        className="searchInput" 
                        placeholder="Search by monitor name or device type" 
                        autoComplete="off"/>

                    <span className="searchButton" onClick={searchButtonPressed}>Search</span>
                </div>
                {
                    props.layout === SignupLayout && <LoginForm page={false} />
                }
                {
                    props.layout === LoginLayout && <LoginForm page={true}/>
                }
            </div>
        </>
    );
}

export default Overlay;