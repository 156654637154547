export const AddAction = "Add";
export const RemoveAction = "Remove";

export const LoginLayout = "Login";
export const SignupLayout = "Signup";
export const SearchLayout = "Search";

export const Host = "http://localhost:3001";
export const HostData = `${Host}/api/data/`;
export const HostLogin = `${Host}/api/login`;
export const HostRegister = `${Host}/api/signup`;