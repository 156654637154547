import React from "react";
import "../css/CopyRight.css";
import { CiLinkedin,CiTwitter, CiInstagram  } from "react-icons/ci";
import { FaDiscord } from "react-icons/fa";

const Copyright = (props) => {
    return (
        <>
            <div id="copyright">
                <div>
                    <CiTwitter className="social"/>
                    <CiLinkedin  className="social"/>
                    <FaDiscord className="social"/>
                    <CiInstagram  className="social"/>
                </div>
                <span className="copyrightText">Copyright © 2024 Monit Marketplace. All Rights Reserved.</span>
            </div>
        </>
    )
}

export default Copyright;