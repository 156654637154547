import './App.css';
import Navbar from "./components/Navbar";
import MainContainer from './Layouts/MainContainer';
import Overlay from './Layouts/Overlay';
import { OverlayContext } from './Context/ContextProvider';
import { useState } from 'react';
import { SearchLayout } from './Context/Constant';
import Footer from './components/Footer';
import Copyright from './components/CopyRight';

function App() {
  const [layout, setLayout] = useState(SearchLayout);

  const showVisibility = (layout = SearchLayout) => {
    var popUp = document.querySelector("#overlay");
    popUp.style.display = "block";

    //set the layout in overlay
    setLayout(layout);
  }

  return (
    <div className="App">
      <OverlayContext.Provider value={showVisibility}>
        <Navbar brandName="Monit Marketplace"/>
        <MainContainer />
        <Overlay layout={layout}/>
        <Footer />
        <Copyright />
      </OverlayContext.Provider>
    </div>
  );
}

export default App;
