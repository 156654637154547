import React, { useState } from "react";
import "../../css/Auth.css";
import { HostLogin, HostRegister } from "../../Context/Constant";
import axios from 'axios';

const LoginForm = (props) => {

    const [logInCheck, setLoginPage] = useState(props.page);

    const login = () => {
        const email = document.querySelector("#loginEmail").value;
        const password = document.querySelector("#loginPassword").value;

        if(logInCheck){
            axios.post(HostLogin, {email, password}).then(response => {
                //Handle successful response
                console.log(response.status);
              })
              .catch(error => {
                // Handle error
                console.error('Error:', error);
              });
        }else{
            axios.post(HostRegister, {email, password}).then(response => {
                //Handle successful response
                console.log(response.status);
              })
              .catch(error => {
                // Handle error
                console.error('Error:', error);
              });
        }
    }

    const changeToRegister = () => {
        setLoginPage(!logInCheck);
    }

    return (
        <>
            <div className="formContainer">
                {
                    logInCheck ? <p>Welcome back User</p> : <p>Create An Account</p>
                }
                <div className="flex-col">
                    <label htmlFor="loginEmail">Email</label>
                    <input type="text" placeholder="Your Email" id="loginEmail"/>
                </div>
                <div className="flex-col">
                    <label htmlFor="loginPassword">Password</label>
                    <input type="password" placeholder="Password" id="loginPassword"/>
                </div>
                {
                    logInCheck && <div className="flex-row">
                        <span></span>
                        <span className="link">Forget Password ?</span>
                    </div>
                }

                {
                    logInCheck ? <button className="loginButton" onClick={login}>Login</button> : <button className="loginButton" onClick={login}>Register</button>
                }

                {
                    logInCheck 
                        ? <span className="newLink">Create an account <span className="link" onClick={changeToRegister}>Register</span></span> 
                        : <span className="newLink">Already have an account <span className="link" onClick={changeToRegister}>Login</span></span>
                }
                
                
            </div>
        </>
    )
}

export default LoginForm;